import { put, take } from "redux-saga/effects";

import { NormalizedData } from "../../types/common";
import { Requirement } from "../../types/generated/q-fanar-requirements.types";
import { INITIALIZE_REQUIREMENTS } from "../actions/init.actions";
import { LOAD_REQUIREMENTS, LOAD_REQUIREMENTS_SUCCESS } from "../actions/requirements.actions";
import { requirementsClient } from "../../api/requirementsClient";
import { GqlQueryOperationResult } from "../epics/requirements.epic";

import { GET_REQUIREMENTS } from "../../queries/requirements";
import { vesselScheduleLifecycleClient } from "../../api/vesselScheduleLifecycleClient";
import { GET_REQUIREMENT_VESSEL_MAP } from "../../queries/schedules";

export function* watchForLoadRequirementsSaga() {
	while (true) {
		yield take(INITIALIZE_REQUIREMENTS);
		yield put({ type: LOAD_REQUIREMENTS });

		const requirementsResult: GqlQueryOperationResult<
			typeof GET_REQUIREMENTS
		> = yield requirementsClient.query(GET_REQUIREMENTS).toPromise();

		const normalizedData = requirementsResult.data?.filterCompleteRequirements?.reduce<
			NormalizedData<Requirement>
		>(
			(acc, current) => {
				acc.byId[current!.id] = current as Requirement;
				acc.allIds.push(current!.id);
				return acc;
			},
			{ byId: {}, allIds: [] }
		);

		const getVesselMapResult: GqlQueryOperationResult<
			typeof GET_REQUIREMENT_VESSEL_MAP
		> = yield vesselScheduleLifecycleClient
			.query(
				GET_REQUIREMENT_VESSEL_MAP,
				{
					requirementIDs: normalizedData?.allIds,
				},
				{ requestPolicy: "network-only" }
			)
			.toPromise();

		const normalizedData2 = getVesselMapResult.data?.getRequirementVesselMap?.reduce<
			NormalizedData<String>
		>(
			(acc, current) => {
				acc.byId[current!.id] = current.vesselId;
				acc.allIds.push(current!.id);
				return acc;
			},
			{ byId: {}, allIds: [] }
		);

		yield put({
			type: LOAD_REQUIREMENTS_SUCCESS,
			payload: {
				vesselById: normalizedData2?.byId,
				...normalizedData,
			},
		});
	}
}
