import { Anchor, Box, ColumnConfig, DataTable } from "grommet";
import React, { useContext } from "react";
import { useSelector } from "@xstate/react";
import { useHistory } from "react-router";
import { VesselWithQ88PRandUtOutput as VesselWithQ88PRandUt } from "../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { FleetMachineContext } from "../App";
import { Edit } from "grommet-icons";
import styled from "styled-components";
import { toDateFormat } from "../constants";

interface IProps {
	onRowClick: (rowId: string) => void;
	isLoading?: boolean;
	vesselId?: string;
}

const StyledAnchor = styled(Anchor)`
	&:hover {
		svg {
			stroke: #59b5d9;
		}
		height: 14px;
		width: 14px;
		color: #59b5d9;
		text-decoration: none;
	}
`;

export const VesselTable = ({ onRowClick, isLoading, vesselId }: IProps) => {
	const fleetMachine = useContext(FleetMachineContext);
	const history = useHistory();
	const getEditClickHandler = React.useCallback(
		(vessel: VesselWithQ88PRandUt) => () => {
			history.push(`/vessels/edit/${vessel.id}/${vessel.vessel.name}`);
		},
		[history]
	);

	const handleVesselClick = React.useCallback(
		(event) => {
			event.preventDefault();
			//set state on the parent (vessels page)
			onRowClick(event.datum.id);
		},
		[onRowClick]
	);

	const vessels = useSelector(fleetMachine, ({ context: { vessels } }) => vessels);

	const columns: ColumnConfig<VesselWithQ88PRandUt>[] = React.useMemo(
		() => [
			{
				property: "id",
				header: "IMO",
				primary: true,
				search: true,
				sortable: true,
				size: "small",
				render: (datum) => (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: 14,
							fontWeight: 700,
						}}
					>
						{datum.vessel.id}
					</div>
				),
			},
			{
				property: "vessel.name",
				header: "Name",
				search: true,
				sortable: true,
				size: "small",
				render: (datum) => (
					<div
						style={{
							display: "flex",

							flexDirection: "row",
							fontSize: 14,
							fontWeight: 500,
						}}
					>
						{datum.vessel.name}
					</div>
				),
			},
			{
				property: "vessel.canCarryProducts",
				header: "Assigned Products",
				search: true,
				sortable: true,
				size: "large",
				render: (datum) => (
					<div
						style={{
							display: "flex",
							width: 340,
							flexWrap: "wrap",
							flexDirection: "row",
							fontSize: 13,
							fontWeight: 400,
						}}
					>
						{datum.vessel.canCarryProducts?.map((p, index) => (
							<div style={{ flexBasis: "50%" }} key={index}>
								{p}
							</div>
						))}
					</div>
				),
			},

			{
				property: "vessel.details.contractExpiration",
				header: "Expiration",
				search: true,
				sortable: true,
				render: (datum) => (
					<Box align="start">
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								fontSize: 14,
								fontWeight: 500,
							}}
						>
							{toDateFormat(datum.vessel.details.contractExpiration)}
						</div>
					</Box>
				),
			},
			{
				property: "vessel.details.sizeCategory",
				header: "Size",
				search: false,
				sortable: true,
				size: "xxlarge",
				render: (datum) => (
					<Box align="start">
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								fontSize: 14,
								fontWeight: 500,
							}}
						>
							{datum.vessel.details.sizeCategory.toUpperCase()}
						</div>
					</Box>
				),
			},
			{
				property: "vessel.isClean",
				header: "Is Clean?",
				align: "center",
				render: (datum) => (
					<Box align="center">
						{datum.vessel.isClean.toString().toLowerCase() === "true" ? (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									fontSize: 14,
									fontWeight: 500,
								}}
							>
								Clean
							</div>
						) : (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									fontSize: 14,
									fontWeight: 500,
								}}
							>
								Dirty
							</div>
						)}
					</Box>
				),
				search: false,
				sortable: true,
			},
			{
				property: "vessel.isParked",
				header: "Laid Up",
				size: "medium",
				align: "center",
				render: (datum) => (
					<Box align="center">
						{datum.vessel.isParked.toString().toLowerCase() === "true" ? (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									fontSize: 14,
								}}
							>
								Laid Up
							</div>
						) : (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									fontSize: 14,
								}}
							>
								Active
							</div>
						)}
					</Box>
				),
				search: false,
				sortable: true,
			},
			{
				property: "edit",
				header: "Edit",
				align: "center",
				sortable: false,
				render: (datum) => (
					<Box onClick={getEditClickHandler(datum)}>
						<StyledAnchor color="#808191" href="#" icon={<Edit />} />
					</Box>
				),
			},
		],
		[getEditClickHandler]
	);
	const step = 15;

	const [sort, setSort]: [
		sort: {
			property: string;
			direction: "desc" | "asc";
			external?: boolean | undefined;
		},
		setSort: any
	] = React.useState({
		property: "name",
		direction: "desc",
	});

	return (
		<Box pad="small">
			{vessels && (
				<>
					<DataTable
						columns={columns}
						data={vessels}
						step={step}
						sort={sort}
						onSort={setSort}
						sortable={true}
						onClickRow={handleVesselClick}
						pad={{ horizontal: "xsmall", vertical: "xxsmall" }}
						background={{
							header: {
								color: "blue1",
							},
							body: ["light-1", "light-3"],
							footer: "dark-3",
						}}
						fill
						paginate
						primaryKey
						rowProps={{
							[`${vesselId}`]: {
								background: "#72B082",
							},
						}}
					/>
					Total vessels: {vessels.length}
					<br />
					Active vessels: {vessels.filter((v) => !v.vessel.isParked).length}
					{/* <pre>{JSON.stringify(vessels, null, 2)}</pre> */}
				</>
			)}
		</Box>
	);
};
