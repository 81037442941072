import React, { memo, useCallback, useContext } from "react";
import type { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import {
	DELETE_REQUIREMENT,
	DISMISS_DELETE_PROMPT,
	IDeleteRequirementAction,
	IRequirementReducerActions,
} from "../../store/actions/requirements.actions";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import { IState } from "../../store/reducers";
import { Button } from "grommet";
import { RequirementAsInput } from "../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { FleetMachineContext } from "../../App";

export const ConfirmDeleteDialog = memo(function ConfirmDeleteDialog() {
	const dispatch = useDispatch<Dispatch<IRequirementReducerActions>>();
	const toBeDeleted = useSelector(
		({ requirements: { requirementIdToBeDeleted } }: IState) =>
			requirementIdToBeDeleted
	);
	const fleetMachineService = useContext(FleetMachineContext);

	const requirement: any = useSelector((state: IState):
		| RequirementAsInput
		| undefined =>
		toBeDeleted
			? (state.requirements.byId[toBeDeleted] as RequirementAsInput)
			: undefined
	);

	const onConfirm = useCallback(() => {
		let deleteAction: IDeleteRequirementAction = {
			type: DELETE_REQUIREMENT,
			payload: toBeDeleted || "",
			fleetService: fleetMachineService,
		};
		if (toBeDeleted)
			dispatch(deleteAction);
	}, [dispatch, toBeDeleted, fleetMachineService]);

	const onCancel = useCallback(() => {
		dispatch({ type: DISMISS_DELETE_PROMPT });
	}, [dispatch]);

	return (
		<Dialog
			open={toBeDeleted !== undefined}
			onClose={onCancel}
			maxWidth="xs"
			fullWidth
		>
			<DialogTitle>Are you sure?</DialogTitle>
			<DialogContent>
				Are you sure you want to delete requirement{" "}
				{requirement?.shipmentId || toBeDeleted}?
			</DialogContent>
			<DialogActions>
				<Button
					label="Yes"
					color="status-critical"
					onClick={onConfirm}
				/>
				<Button
					label="Cancel"
					color="status-unknown"
					onClick={onCancel}
				/>
			</DialogActions>
		</Dialog>
	);
});
