import { useMemo } from "react";
import { useAuthContext } from "../context/AuthContext";
import user from "../context/UserContext";
import { Configuration } from "../util/config";

function t<V extends string, T extends { [key in string]: V | Array<any> }>(
	o: T
): T {
	return o;
}

export const Roles = t(<const>{
	FFA: "FanarFullAccess",
	OPT: "Optimizer",
	REQ: "Requirements Admin",
	VESS: "Vessel Admin",
	USER: "User Admin",
	READ: "Read Only",
	CWF: "CWF",
});

type RoleKeys = keyof typeof Roles;
export type UserRole = typeof Roles[RoleKeys];

const { FFA, OPT: OP } = Roles;
// const FOP = [FFA, OP];
export const AccessActions = t<string, { [key: string]: UserRole[] }>({
	// Fleet
	"view fleet page": [FFA, OP, Roles.READ], // [...FOP, Roles.READ]
	"assign requirement to vessel": [FFA, OP], // FOP
	"assign requirement to spot": [FFA, OP], // FOP
	"lock/unlock requirements": [FFA, OP], // FOP
	"finish spots": [FFA, OP], // FOP
	"promote plan": [FFA, OP], // FOP
	"calculate plan": [FFA, OP], // FOP
	// Vessels
	"view vessels page": [FFA, OP], // FOP
	"view vessel": [FFA, OP], // FOP
	"manage vessels": [FFA],
	"view vessel report": [FFA, OP], // FOP
	"manage pr/ut": [FFA, OP], // FOP
	// Requrements
	"view requirements page": [FFA, OP, Roles.REQ],
	"view requirements": [FFA, OP, Roles.REQ],
	"manage requirements": [FFA, OP, Roles.REQ],
	// Reports
	"view reports page": [FFA, OP], // FOP
	"manage reports": [FFA, OP], // FOP
	"generate fleet report": [FFA, OP],
	"generate vp report": [FFA, OP],
	// Operational parameters
	"view operational parameters page": [FFA],
});

export type AccessActionsKeys = keyof typeof AccessActions;
export type Action = typeof AccessActions[AccessActionsKeys];

const isRoleSwitchAvailabe = Configuration.REACT_APP_SHOW_ROLE_SWITCH || false;
const roleManagerCallback = (): any => {
	const selectedRole = isRoleSwitchAvailabe
		? localStorage.getItem("defaultRole") || Roles.FFA
		: "auth0";

	const userProfileRole = user.getUserProfile()?.[
		`${Configuration.REACT_APP_PORTAL_AUTH_IDENTIFIER}roles`
	];

	const roles: UserRole[] =
		!selectedRole || selectedRole === "auth0"
			? userProfileRole && userProfileRole.length
				? userProfileRole
				: [Roles.READ]
			: [selectedRole];

	const hasRole = (role: UserRole): boolean => {
		return roles.includes(role);
	};

	const hasRoles = (_roles: UserRole[]) => {
		let can = false;
		for (const role of _roles) {
			if (hasRole(role)) {
				can = true;
				break;
			}
		}
		return can;
	};

	return {
		hasRole,
		hasRoles,
		can: (action: AccessActionsKeys) => hasRoles(AccessActions[action]),
	};
};

/**
 * A function that returns an object with functions to check if the logged in user has a particular role
 * @example
 * const { hasRole, hasRoles } = getRoleManager();
 * return (
 * 		hasRole('User Admin') ||
 * 		hasRole(['User Admin', 'Optimizer']) ||
 * 		can("delete all")
 * 	) && <Button label="Delete All" />
 */
export const getRoleManager = () => {
	return roleManagerCallback();
};

/**
 * A hook that returns an object with functions to check if the logged in user has a particular role
 * @example
 * const { hasRole, hasRoles } = useRoleManager();
 * return (
 * 		hasRole('User Admin') ||
 * 		hasRole(['User Admin', 'Optimizer']) ||
 * 		can("delete all")
 * 	) && <Button label="Delete All" />
 */
export const useRoleManager = () => {
	const token = useAuthContext()?.token;
	const userProfile = user.getUserProfile();
	return useMemo(getRoleManager, [token, userProfile]);
};
