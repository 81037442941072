// import { cacheExchange } from "@urql/exchange-graphcache";
// import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";
import { createClient, dedupExchange, fetchExchange } from "urql";

import UserContext from "../context/UserContext";
import { Configuration } from "../util/config";
import { createApolloClient } from "./apolloClient";
import { APIClientConfig } from "./types";
// import { commonUrlqRetry } from "./utils";

// const storage = makeDefaultStorage();
//commonUrlqRetry -> for retry logic if socket hang-up occurs
const exchanges = [
	dedupExchange,
	// cacheExchange({ storage }),
	// commonUrlqRetry,
	fetchExchange,
];

// * Vessel Schedule Lifecycle RC5
const VESSEL_SCHEDULE_LIFECYCLE_URL =
	Configuration.VESSEL_SCHEDULE_LIFECYCLE_URL;

const vslClientConfig: APIClientConfig = {
	cacheKey: "fanar-vsl-client",
	uri: VESSEL_SCHEDULE_LIFECYCLE_URL,
	fetchPolicy: "network-only",
};

export const vesselScheduleLifecycleClient_apollo = createApolloClient(
	vslClientConfig
);

export const vesselScheduleLifecycleClient = createClient({
	url: VESSEL_SCHEDULE_LIFECYCLE_URL,
	fetchOptions: () => {
		return { headers: UserContext.getAuthHeader() };
	},
	exchanges,
});
