import { useContext, memo, useCallback, useState, useEffect, Dispatch, useMemo } from "react";

import { Text, Box, Button } from "grommet";
import { useSelector } from "@xstate/react";
import { useSelector as useReduxSelector, useDispatch } from "react-redux";
import { Requirement } from "../../../types/generated/q-fanar-requirements.types";
import { FleetMachineContext } from "../../../App";
import {
	I_ActualScheduledRequirement,
	I_ActualVesselSchedule,
	RequirementAsInput,
} from "../../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { portsSelector } from "../../../selectors/ports.selectors";
import { validateRequirement, RequirementValidationError } from "../../../selectors/requirement.selectors";
import { IState } from "../../../store/reducers";
import Alert from "@material-ui/lab/Alert";
import {
	DISMISS_SNACKBAR,
	IRequirementReducerActions,
	RECONSIGN_REQUIREMENT,
} from "../../../store/actions/requirements.actions";
import { CircularProgress } from "@material-ui/core";

import { isLoadingSelector, errorMessageSelector } from "../../../selectors/requirement.selectors";
import { useRoleManager } from "../../../hooks/useRoleManager";
import CommonErrorModal from "../../CommonErrorModal";
import { OffScheduleRequirementInfo } from "./OffScheduleRequirementInfo";
import { RequirementActionsInfo } from "./RequirementActionsInfo";
import { TradesTable, TradeRow, RequirementEditabilityOptions } from "./TradesTable";
import { recursivelyDeleteTypenameProperty } from "../../../store/util/clean-props";

export const FleetRequirementDetails = memo(function FleetRequirementDetails() {
	const dispatch = useDispatch<Dispatch<IRequirementReducerActions>>();
	const fleetMachineService = useContext(FleetMachineContext);

	const [isRequirementBeingEdited, setIsRequirementBeingEdited] = useState(false);

	const [errors, setErrors] = useState<RequirementValidationError[] | undefined>([]);

	const [shorts, setShorts] = useState<TradeRow[]>([]);
	const [longs, setLongs] = useState<TradeRow[]>([]);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [selectedRequirement, setSelectedRequirement] = useState<Requirement | undefined>();

	const ports = useReduxSelector(portsSelector);

	const requirements = useReduxSelector((state: IState) =>
		state.requirements?.allIds.map((id) => state.requirements.byId[id])
	);
	const getPortName = useCallback((id: string) => ports.byId[id]?.name ?? id, [ports]);

	const isSomethingLoading = useReduxSelector(isLoadingSelector);
	const errorMessage = useReduxSelector(errorMessageSelector);

	const getTerminalName = useCallback(
		(portId?: string | null, terminalId?: string | null): string | undefined => {
			if (!terminalId || !portId) return;
			const port = ports.byId[portId];
			if (!port) return;
			const terminal = port.terminals?.find(({ terminalID }) => terminalID === terminalId);
			if (!terminal) return;
			return terminal.name ?? undefined;
		},
		[ports]
	);

	type RequirementAndActualScheduleTuple = {
		requirementInActualSchedule: I_ActualScheduledRequirement;
		schedule: I_ActualVesselSchedule;
	};

	const { requirementInActualSchedule } = useSelector(
		fleetMachineService,
		({ context: { selectedRequirementId, schedule } }): Partial<RequirementAndActualScheduleTuple> => {
			if (!selectedRequirementId?.id) return {};
			return (
				schedule
					.flatMap(({ actualSchedule }) =>
						(actualSchedule?.requirements ?? []).map((requirement) => ({
							requirementInActualSchedule: requirement,
							schedule: actualSchedule!,
						}))
					)
					.find(
						({ requirementInActualSchedule: { requirementId } }) =>
							requirementId === selectedRequirementId.id
					) ?? {}
			);
		},
		(a, b) => a.requirementInActualSchedule === b.requirementInActualSchedule && a.schedule === b.schedule
	);
	const loadedRequirementDetails = useSelector(
		fleetMachineService,
		({ context: { requirementDetails } }) => requirementDetails
	);

	let countOfLongs = 0;
	let countOfShorts = 0;
	let requirementIsSingleLoadSingleDischarge = false;
	let loadingIsInProgress = false; // can edit short: Port Terminal StartDate EndDate (dates should later than today)
	let waitingBeforeDischargeIsInProgress = false; // can edit short: Port Terminal StartDate EndDate (dates should later than today)
	let waitingBeforeTravelToDischargeIsInProgress = false; // can edit short: Port Terminal StartDate EndDate (dates should later than today)
	let dischargeIsInProgress = false; // can edit short: Quantity, For next short: Port Terminal StartDate EndDate Quantity (dates should later than today and later than the first short)
	let currentActionIndex = -1;

	if (loadedRequirementDetails && requirementInActualSchedule) {
		countOfLongs = loadedRequirementDetails.longs?.length || 0;
		countOfShorts = loadedRequirementDetails.shorts?.length || 0;
		requirementIsSingleLoadSingleDischarge = countOfLongs === 1 && countOfShorts === 1;
		currentActionIndex = requirementInActualSchedule.actualVesselActions.findIndex((action) => {
			return action.actionStatus.id === "In Progress";
		});
		if (currentActionIndex !== -1) {
			loadingIsInProgress =
				requirementInActualSchedule.actualVesselActions[currentActionIndex].vesselAction.actionType.id ===
				"load";
			dischargeIsInProgress =
				requirementInActualSchedule.actualVesselActions[currentActionIndex].vesselAction.actionType.id ===
				"unload";
			waitingBeforeDischargeIsInProgress =
				requirementInActualSchedule.actualVesselActions[currentActionIndex + 1]?.vesselAction.actionType.id ===
					"unload" &&
				requirementInActualSchedule.actualVesselActions[currentActionIndex].vesselAction.actionType.id ===
					"wait";
			waitingBeforeTravelToDischargeIsInProgress =
				requirementInActualSchedule.actualVesselActions[currentActionIndex].vesselAction.actionType.id ===
					"wait" &&
				requirementInActualSchedule.actualVesselActions[currentActionIndex + 1]?.vesselAction.actionType.id ===
					"voyage" &&
				(requirementInActualSchedule.actualVesselActions[currentActionIndex + 2]?.vesselAction.actionType.id ===
					"unload" ||
					(requirementInActualSchedule.actualVesselActions[currentActionIndex + 2]?.vesselAction.actionType
						.id === "wait" &&
						requirementInActualSchedule.actualVesselActions[currentActionIndex + 3]?.vesselAction.actionType
							.id === "unload"));
		}
	}

	let requirementIsAdjustable =
		requirementIsSingleLoadSingleDischarge &&
		(loadingIsInProgress ||
			waitingBeforeDischargeIsInProgress ||
			// dischargeIsInProgress ||
			waitingBeforeTravelToDischargeIsInProgress);

	const setDefaultLongsAndShorts = useCallback(() => {
		const longs =
			loadedRequirementDetails?.longs?.map<TradeRow>((long, index, array) => ({
				...long,
				port: long.port,
				terminal: long.terminal!,
				terminalName: getTerminalName(long.port, long.terminal),
				portName: getPortName(long.port ?? ""),
				index,
				arrayLength: array.length,
				isLong: true,
			})) ?? [];

		const shorts =
			loadedRequirementDetails?.shorts?.map<TradeRow>((short, index, array) => ({
				...short,
				port: short.port,
				terminal: short.terminal!,
				terminalName: getTerminalName(short.port, short.terminal),
				portName: getPortName(short.port ?? ""),
				index,
				arrayLength: array.length,
				isLong: false,
			})) ?? [];

		if (isRequirementBeingEdited && dischargeIsInProgress && shorts?.length === 1) {
			// TODO actually, have to add/remove an extra short when isRequirementBeingEdited and dischargeIsInProgress, probably a useEffect
			const newShort = { ...shorts[0] };
			newShort.index = 1;
			newShort.id = newShort.id + "1";
			newShort.port = undefined;
			newShort.portName = undefined;
			newShort.terminal = undefined;
			newShort.terminalName = undefined;
			newShort.productQuantity = 1;
			shorts[0].productQuantity--;
			newShort.startDate = Math.ceil(new Date().getTime() / 1000);
			newShort.endDate = Math.ceil(new Date().getTime() / 1000);

			shorts[1] = newShort;
			shorts[0].arrayLength = 2;
			shorts[1].arrayLength = 2;
		}

		setLongs(longs);
		setShorts(shorts);
	}, [loadedRequirementDetails, getTerminalName, getPortName, isRequirementBeingEdited, dischargeIsInProgress]);

	useEffect(() => {
		setDefaultLongsAndShorts();
		setSelectedRequirement(loadedRequirementDetails);
	}, [setDefaultLongsAndShorts, loadedRequirementDetails, isRequirementBeingEdited]);

	useEffect(() => {
		if (isRequirementBeingEdited) {
			if (!isSomethingLoading) {
				if (!Boolean(errorMessage)) {
					if (selectedRequirement?.locked && selectedRequirement?.locked === "") {
						selectedRequirement.locked = null;
					}
					const requirement = {
						...selectedRequirement!,
						longs: longs,
						shorts: shorts,
					};
					setIsRequirementBeingEdited(false);
					setIsDirty(false);
					setSelectedRequirement(requirement);
				} else {
					console.log("SOMETHING WENT WRONG"); // why this could be?
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSomethingLoading, errorMessage]);

	const validate = async (row: TradeRow) => {
		var newLongs = [...(longs || [])];
		var newShorts = [...(shorts || [])];

		let totalProductQuantity = newLongs.reduce<number>((acc, long) => acc + long.productQuantity, 0);
		if (dischargeIsInProgress && requirementIsSingleLoadSingleDischarge) {
			if (!row.isLong && row.index === 1) {
				newShorts[0].productQuantity = totalProductQuantity - row.productQuantity;
			}
		}

		if (row.isLong === true) {
			newLongs = [...longs?.filter((f) => f.id !== row.id)!, row];
			setLongs(newLongs);
		} else {
			newShorts = [...shorts?.filter((f) => f.id !== row.id)!, row];
			setShorts(newShorts);
		}

		const validationResult: RequirementValidationError[] = validateRequirement(
			{
				...selectedRequirement!,
				longs: newLongs,
				shorts: newShorts,
			},
			ports,
			requirements
		).filter((error) => {
			if (!error.keys?.length) return true;

			if (error.keys.some((key) => key.startsWith("short"))) {
				if (
					dischargeIsInProgress &&
					requirementIsSingleLoadSingleDischarge &&
					error.keys.length === 1 &&
					error.keys[0] === "shorts.0"
				) {
					return false;
				}
				return true;
			}
			return false;
		});

		if (isDirty === false) {
			setIsDirty(true);
		}

		if (newShorts.some((short) => short.productQuantity <= 0)) {
			validationResult.push({ message: "Product quantity can't be zero or negative" });
		}

		const newDischargePort = newShorts[newShorts.length - 1].port;
		if (newDischargePort && newDischargePort === loadedRequirementDetails?.shorts?.[0].port) {
			validationResult.push({ message: "New discharge port should be different from the old one" });
		}

		if (newDischargePort && loadingIsInProgress && newDischargePort === loadedRequirementDetails?.longs?.[0].port) {
			validationResult.push({ message: "New discharge port should be different from the loading port" });
		}

		if (currentActionIndex !== -1) {
			if (
				newDischargePort &&
				waitingBeforeTravelToDischargeIsInProgress &&
				newDischargePort === requirementInActualSchedule?.actualVesselActions[currentActionIndex].endState.port
			) {
				validationResult.push({ message: "New discharge port should be different from the current port" });
			}
		}

		if (requirementIsSingleLoadSingleDischarge && newShorts[1]) {
			if (newShorts[1]?.startDate <= newShorts[0]?.startDate) {
				validationResult.push({ message: "Second short dates should be later then first short dates" });
			}
		}

		setErrors(validationResult);
	};

	// const updateRequirement = useCallback(() => undefined, []);
	// // TODO!!! after dispatch is done switch state machine to loading state
	// // maybe through history.push("/fleet");
	// // or, actually, just close+reload, if ok, and
	const updateRequirement = useCallback(() => {
		const req = {
			...selectedRequirement!,
			longs: longs!.map(({ portName, terminalName, index, arrayLength, isLong, ...rest }) => rest),
			shorts: shorts.map(({ portName, terminalName, index, arrayLength, isLong, ...rest }) => rest),
		} as RequirementAsInput;
		dispatch({
			type: RECONSIGN_REQUIREMENT,
			payload: {
				vesselID: requirementInActualSchedule?.vesselId,
				modifiedRequirementToReconsign: recursivelyDeleteTypenameProperty(req),
			},
			fleetService: fleetMachineService,
		});
	}, [dispatch, longs, shorts, selectedRequirement, fleetMachineService, requirementInActualSchedule?.vesselId]);

	const clearError = () => {
		if (Boolean(errorMessage)) {
			dispatch({ type: DISMISS_SNACKBAR });
		}
	};

	const { can } = useRoleManager();
	const canViewRequirement = can("view requirements");
	const canManageRequirement = can("manage requirements");

	let requirementEditabilityOptions: RequirementEditabilityOptions = useMemo(() => {
		const longs = loadedRequirementDetails?.longs || [];
		const shorts = loadedRequirementDetails?.shorts || [];
		const options: RequirementEditabilityOptions = {
			longs: [],
			shorts: [],
		};
		if (!isRequirementBeingEdited) return options;
		options.longs = longs.map((trade) => ({}));
		if (shorts.length === 1) {
			if (
				loadingIsInProgress ||
				waitingBeforeDischargeIsInProgress ||
				waitingBeforeTravelToDischargeIsInProgress
			) {
				options.shorts.push({ port: true, terminal: true, startDate: true, endDate: true });
			} else if (dischargeIsInProgress) {
				options.shorts.push({});
				options.shorts.push({
					port: true,
					terminal: true,
					startDate: true,
					endDate: true,
					productQuantity: true,
				});
			}
		}
		return options;
	}, [
		loadedRequirementDetails,
		dischargeIsInProgress,
		loadingIsInProgress,
		waitingBeforeDischargeIsInProgress,
		waitingBeforeTravelToDischargeIsInProgress,
		isRequirementBeingEdited,
	]);

	return (
		<Box>
			{(canViewRequirement || canManageRequirement) && selectedRequirement && (
				<Box pad="medium">
					<OffScheduleRequirementInfo />
					<RequirementActionsInfo selectedRequirement={selectedRequirement} />
					<Box pad="small">
						<Box>
							<h3>Longs</h3>
							<TradesTable
								tradeRows={longs}
								editabilityOptions={requirementEditabilityOptions.longs}
								validate={validate}
							/>
						</Box>
					</Box>

					<Box pad="small">
						<Box>
							<h3>Shorts</h3>
							<TradesTable
								tradeRows={shorts}
								editabilityOptions={requirementEditabilityOptions.shorts}
								validate={validate}
							/>
						</Box>
					</Box>
					{errors && errors.length > 0 && (
						<Box pad="medium">
							{errors.map(({ message }) => (
								<Alert key={message} style={{ marginBottom: 4 }} severity="error">
									<Text size="medium">{message}</Text>
								</Alert>
							))}
						</Box>
					)}

					{isSomethingLoading ? (
						<Box
							direction="row"
							gap="small"
							pad="medium"
							alignContent="end"
							justify="end"
							style={{
								width: "100%",
							}}
						>
							<CircularProgress size="32px" />
						</Box>
					) : !!canManageRequirement && requirementIsAdjustable ? (
						<Box
							direction="row"
							gap="small"
							pad="small"
							alignContent="end"
							justify="end"
							style={{
								width: "100%",
							}}
						>
							{isRequirementBeingEdited === false ? (
								<Box pad="small">
									<Button primary onClick={() => setIsRequirementBeingEdited(true)} label="Adjust" />
								</Box>
							) : (
								<>
									<Box pad="small">
										<Button
											onClick={() => {
												setIsRequirementBeingEdited(false);
												setErrors([]);
												setIsDirty(false);
												setDefaultLongsAndShorts();
											}}
											secondary
											label="Cancel"
										/>
									</Box>
									<Box pad="small">
										<Button
											disabled={!!errors?.length || !isDirty}
											onClick={async () => {
												clearError();
												updateRequirement();
											}}
											primary
											label="Save"
										/>
									</Box>
								</>
							)}
						</Box>
					) : (
						<Box pad="small">
							<div>Single-load single-discharge requirements can be edited.</div>
							<div>
								To be editable, any of the following three actions should be in progress:
								<ul>
									<li>Loading</li>
									<li>Waiting for discharge</li>
									{/* <li>Discharge</li> */}
								</ul>
							</div>
						</Box>
					)}

					<CommonErrorModal
						isOpen={Boolean(errorMessage)}
						errorMessage={errorMessage}
						onClose={() => dispatch({ type: DISMISS_SNACKBAR })}
					/>
				</Box>
			)}
		</Box>
	);
});
