import {
	RequirementAsInput,
	TradeAsInput,
} from "../../../types/generated/q-fanar-requirements.types";
import { parse } from "papaparse";
import { groupBy, uniq, difference } from "lodash";
import { IPortsState } from "../ports";
import {
	findPortIdByName,
	findTerminalById,
	findTerminalByName,
} from "../../../selectors/ports.selectors";
import { Product } from "../../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { Requirement } from "../../../types/generated/q-fanar-requirements.types";
import { IPort } from "../../sagas/loadPorts.saga";
import { v4 as uuid } from "uuid";

export interface BatchRequirementItem {
	item: RequirementAsInput;
	/**
	 * Warnings to present to the user that were discovered when parsing the item
	 * (For example - mismatching values for the same short or long)
	 * As user pushes "Resolve" - these warnings will be excluded from the array
	 *
	 * In addition to these - conflicting dates will be detected within selector -
	 * and will need to be resolved by fixing values within the form to make the requirement valid
	 */
	warnings: string[];
}

export interface ParsedRequirements {
	/** Requirements that were created from the CSV */
	requirements: BatchRequirementItem[];
	/** Error messages for errors that would not end up in parsed requirements - such as incorrect columns, etc */
	formattingIssues: string[];
	/** While the gql mutation is executing */
	isSubmitting: boolean;
	overrides?: string[];
}

function hashStr(str: string): string {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = ((hash << 5) - hash + str.charCodeAt(i)) | 0;
	}
	return (hash >>> 0).toString(36);
}

function findPort(
	ports: IPortsState,
	portId?: string,
	portName?: string
): IPort | undefined {
	return ports.byId[portId || (findPortIdByName(ports, portName) as string)];
}

/** Determines the port and terminal ID to use based on the given inputs */
function terminalAndPortId(
	ports: IPortsState,
	portId?: string,
	portName?: string,
	terminalId?: string,
	terminalName?: string
): { port?: string; terminal?: string } {
	if (portName || portId) {
		const pName = portName?.toLowerCase().trim();
		const tName = terminalName?.toLowerCase().trim();
		// If port is specified - search for terminal only in the specified port
		const port = findPort(ports, portId, pName);
		if (port)
			return {
				port: port.id,
				terminal:
					(terminalId ||
						port.terminals?.find(
							({ name: fName }) => fName?.toLowerCase() === tName
						)?.id) ??
					"",
			};
	}

	// Otherwise just search within all ports
	const terminal = terminalId
		? findTerminalById(ports, terminalId)
		: findTerminalByName(ports, terminalName);

	const port: any =
		((portId || findPortIdByName(ports, portName)) ?? terminal?.port.id) ||
		portName;
	return { port, terminal: terminal?.terminal.id ?? "" };
}

function tradeKey(
	start: string,
	end: string,
	product: string,
	quantity: string,
	portId: string,
	portName: string
) {
	return `${dateToUnixTime(start)}__${dateToUnixTime(
		end,
		true
	)}__${product.trim()}__${quantity.trim()}__${(
		(portId || portName) ??
		""
	).trim()}`;
}

const dateRe = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/;
/** Converts a date from MM/DD/YYYY to unix time (seconds) */
export function dateToUnixTime(
	date: string,
	/** If true, interpreted as 23:00 Arabian Standard Time, else time is 05:00 Arabian Standard Time */
	isEnd: boolean = false
): number {
	const match = dateRe.exec(date);
	if (!match) return 0;
	const [, mm, dd, yyyy] = match;
	return (
		Math.floor(
			new Date(
				`${yyyy.padStart(4, "20")}-${mm.padStart(2, "0")}-${dd.padStart(
					2,
					"0"
				)}T00:00:00.000Z`
			).valueOf() / 1000
		) -
		3 * 60 ** 2 +
		(isEnd ? 23 : 5) * 60 ** 2
	);
}

const expectedColumns = [
	"shipment",
	"startDate.long",
	"endDate.long",
	"startDate.short",
	"endDate.short",
	"product.long",
	"product.short",
	"quantity.long",
	"quantity.short",
	"portID.long",
	"portName.long",
	"terminalID.long",
	"terminalName.long",
	"portID.short",
	"portName.short",
	"terminalID.short",
	"terminalName.short",
	"spot_cost",
] as const;

type CsvRecord = Record<typeof expectedColumns[number], string>;

function rowToTrade(
	ports: IPortsState,
	products: Product[],
	/** Just an array that will aggregate all warnings found while trying to parse the csv */
	warnings: string[],
	requirementId: string,
	key: string,
	portId: string,
	portName: string,
	start: string,
	end: string,
	product: string,
	quantity: string,
	terminalName: string,
	terminalID: string,
	isShort = false
): TradeAsInput | null {
	let tradeType = isShort ? "short" : "long";
	try {
		const startDate = dateToUnixTime(start);
		const endDate = dateToUnixTime(end, true);
		if (!startDate || !endDate) {
			warnings.push(
				`Dates for a ${tradeType} did not match the MM/DD/YYYY pattern: start: ${start}, end: ${end}`
			);
		}

		const productDetails = products?.find((p: Product) => p.id === product);
		const cleanStatus = productDetails?.cleanStatus;

		if (!productDetails) {
			warnings.push(`Invalid product name: '${product}' for ${tradeType}.`);
		} else if (!cleanStatus) {
			warnings.push(
				`Could not find a clean status for Product type \`${product}\` for ${tradeType}.`
			);
		}

		const { port, terminal } = terminalAndPortId(
			ports,
			portId,
			portName,
			terminalID,
			terminalName
		);

		if ((terminalID || terminalName) && !terminal)
			warnings.push(
				`Were unable to find the terminal entered in CSV: ${
					terminalID || terminalName
				} for ${tradeType}.`
			);

		return {
			id: `${requirementId}_${tradeType}_${hashStr(
				key + product + port
			)}_${uuid()}`,
			startDate,
			endDate,
			product: {
				id: product,
				cleanStatus,
			},
			productQuantity: +quantity,
			port,
			terminal,
		} as TradeAsInput;
	} catch (e: any) {
		warnings.push(
			`There was an issue parsing ${tradeType} for the requirement: ${e.message}`
		);

		return null;
	}
}

export function csvToRequirements(
	csv: string,
	creator: string,
	ports: IPortsState,
	products: Product[],
	existingRequirements: Requirement[] | null | undefined
): ParsedRequirements {
	const parsed = parse<CsvRecord>(csv, {
		header: true,
		// * skip lines that don't have any content (those which have only whitespace after parsing)
		skipEmptyLines: "greedy",
	});

	const shipments = groupBy(parsed.data, ({ shipment }) => shipment.trim());
	let overrides: string[] = [];

	return {
		isSubmitting: false,
		overrides: overrides,
		formattingIssues: [
			...parsed.errors.map(({ message }) => message),
			...difference(expectedColumns, parsed.meta.fields!).map(
				(field) => `Column \`${field}\` is missing in the provided CSV`
			),
		],
		requirements: Object.entries(shipments).map<BatchRequirementItem>(
			([id, group]) => {
				// Spot cost is per requirement - therefore should be specified as the same for all requirements
				const spotCosts = uniq(group.map(({ spot_cost }) => spot_cost));
				const warnings: any[] = [];

				if (spotCosts.length !== 1) {
					warnings.push(
						`Spot cost is per requirement - the values should be the same for all rows within a single requirement. Rerquirement ${id} has the following values of spot cost ${spotCosts.join(
							", "
						)}`
					);
				}

				// trades that have exact same location, date and product must be merged
				const longs = group
					.filter(({ "quantity.long": q }) => {
						const s = q?.trim();
						if (!s) return false;
						return parseFloat(s) > 0;
					})
					.map<TradeAsInput | null>(
						({
							"portID.long": portId,
							"portName.long": portName,
							"startDate.long": start,
							"endDate.long": end,
							"product.long": product,
							"quantity.long": quantity,
							"terminalName.long": terminalName,
							"terminalID.long": terminalID,
						}) =>
							rowToTrade(
								ports,
								products,
								warnings,
								id,
								tradeKey(
									start,
									end,
									product,
									quantity,
									portId,
									portName
								),
								portId,
								portName,
								start,
								end,
								product,
								quantity,
								terminalName,
								terminalID,
								false
							)
					)
					.filter((t): t is TradeAsInput => t !== null);

				const shorts = group
					.filter(({ "quantity.short": q }) => {
						const s = q?.trim();
						if (!s) return false;
						return parseFloat(s) > 0;
					})
					.map<TradeAsInput | null>(
						({
							"portID.short": portId,
							"portName.short": portName,
							"startDate.short": start,
							"endDate.short": end,
							"product.short": product,
							"quantity.short": quantity,
							"terminalID.short": terminalID,
							"terminalName.short": terminalName,
						}) =>
							rowToTrade(
								ports,
								products,
								warnings,
								id,
								tradeKey(
									start,
									end,
									product,
									quantity,
									portId,
									portName
								),
								portId,
								portName,
								start,
								end,
								product,
								quantity,
								terminalName,
								terminalID,
								true
							)
					)
					.filter((t): t is TradeAsInput => t !== null);

				const existingRequirement = existingRequirements?.find(
					({ shipmentId }) => shipmentId === id
				);

				if (existingRequirement) {
					overrides.push(existingRequirement.shipmentId);
				}

				let requirementItem = {
					id: `${id}_${uuid()}`,
					shipmentId: id,
					createdBy: creator,
					createdAt: Math.floor(Date.now() / 1000),
					// createdAt: new Date("2019-01-01").valueOf() / 1000, // TODO: Remove
					estimatedCostToSpotCharter: +group[0].spot_cost,
					longs,
					shorts,
					status: {
						id: "Not Started",
					},
				};

				if (existingRequirement) {
					return undefined as any;
				}

				return {
					item: requirementItem,
					warnings,
				};
			}
		).filter(Boolean),
	};
}
